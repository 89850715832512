<template>
  <header
    component-name="toolbar-modal-header"
    data-skin="black"
    class="flex items-center justify-between h-20 min-h-[theme(spacing.20)] px-edge text-skin-base bg-skin-base max-w-screen"
  >
    <div class="flex items-center justify-start w-1/2 gap-lg phablet:w-5/12">
      <div v-if="navigation" class="flex items-center gap-xl text-skin-base">
        <div
          v-if="isLaptop"
          v-html="nuxtApp.$t.labels.menu"
          class="truncate text-heading-6"
        ></div>

        <transition
          v-else
          mode="out-in"
          enter-active-class="transition-fade ease-out duration-300 !pointer-events-none"
          :enter-from-class="`opacity-0 ${
            navigation === 'nav' ? '-translate-x-4' : 'translate-x-4'
          }`"
          enter-to-class="opacity-100 translate-x-0"
          leave-active-class="transition-fade ease-out duration-300 !pointer-events-none"
          leave-from-class="opacity-100 translate-x-0"
          :leave-to-class="`opacity-0 ${
            navigation === 'nav' ? 'translate-x-4' : '-translate-x-4'
          }`"
        >
          <btn-nav
            v-if="navigation === 'nav'"
            size="big"
            :title="$t.labels.menu"
            :disabled="true"
          />

          <btn-nav
            v-else
            size="big"
            icon-left="angle-left"
            :title="navigationLabel"
            :disabled="navigation === 'nav'"
            @click="$emit('back')"
          />
        </transition>
      </div>
      <slot v-else name="header-left" />
    </div>

    <DialogTitle class="justify-center hidden tablet:flex">
      <btn-mirage />
    </DialogTitle>

    <div class="flex justify-end w-1/2 phablet:w-5/12">
      <btn-nav
        icon-right="close"
        :title="$t.labels.close"
        @click="$emit('close')"
        class="only-smartphone:!hidden"
      />

      <btn-icon
        icon="close"
        :title="$t.labels.close"
        @click="$emit('close')"
        class="phablet:!hidden"
      />
    </div>
  </header>
</template>

<script setup>
import { DialogTitle } from "@headlessui/vue";

const nuxtApp = useNuxtApp();
const props = defineProps({
  navigation: {
    type: String,
    required: false,
  },
  label: {
    type: String,
    required: false,
  },
});

const isLaptop = useMediaQuery("(min-width: 1272px)");
const navigationLabel = ref();

watch(
  () => props.navigation,
  (newVal, oldVal) => {
    let s;
    switch (newVal) {
      case "products":
        s = nuxtApp.$t.labels.products;
        break;

      case "solutions":
        s = nuxtApp.$t.labels.attr_pa_soluzioni;
        break;

      case "professional":
        s = nuxtApp.$t.labels.professional;
        break;

      case "customizers":
        s = nuxtApp.$t.labels.customizers;
        break;

      case "mirage-world":
        s = nuxtApp.$t.labels.mirage_world;
        break;

      case "locale":
        s = nuxtApp.$t.labels.change_market;
        break;
    }
    if (s) navigationLabel.value = s;
  }
);
</script>

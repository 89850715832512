<template>
  <block
    component-name="block-launcher-slides"
    ref="tpl_root"
    :px="false"
    data-skin="black"
  >
    <template v-slot:default="blockData">
      <div
        class="relative top-0 left-0 flex flex-col laptop:gap-[60vh] bg-skin-base contain-content w-full"
      >
        <div
          inert
          class="visible tablet:visible absolute inset-0 z-50 w-full h-full"
        >
          <div
            class="sticky top-0 left-0 flex flex-col items-end justify-end w-full h-[100svh] pb-[calc(100lvh-100svh)]"
          >
            <wrapper-reveal class="py-4xl px-edge" reveal="fade-from-right">
              <txt-scroll-cta />
            </wrapper-reveal>
          </div>
        </div>

        <section
          v-for="(item, index) in $attrs.data.body.items"
          class="sticky top-0 left-0 w-full max-w-full"
        >
          <div
            class="flex items-stretch justify-start w-full h-screen max-w-full overflow-hidden bg-black px-edge py-4xl"
            v-surfer="{
              setup: {
                rootMargin: '0px 0px -90px 0px',
              },
              observers: {
                init: true,
                offscreenBottom: '[&+span]:!opacity-0 [&+span]:!duration-700',
              },
            }"
          >
            <div
              class="absolute inset-0 w-full h-full overflow-hidden"
              v-surfer="{
                observers: {
                  init:
                    item.asset.variant === 'static'
                      ? 'scale-[120%] opacity-50 origin-top'
                      : 'opacity-50',
                  visible:
                    item.asset.variant === 'static'
                      ? '!scale-100 !opacity-100 transition-fade duration-[4000ms] ease-cubic-out'
                      : '!opacity-100 transition-fade duration-[2000ms] ease-in-out',
                  offscreenTop: '!scale-100 !opacity-100',
                },
              }"
            >
              <media
                class="w-full h-full"
                v-bind="item.asset"
                :sizes="{
                  base: 600,
                  phablet: 1020,
                  tablet: 1272,
                  laptop: 1512,
                  desktop: 1920,
                  extdesktop: 2560,
                }"
                :loading="
                  index > 1 || blockData?.loading === 'lazy' ? 'lazy' : 'auto'
                "
                :use-bg="true"
              />

              <span
                class="absolute inset-0 w-full h-full overlay-gradient-from-bottom"
                inert
              ></span>
            </div>

            <div
              class="relative top-0 left-0 flex flex-col justify-end w-11/12 tablet:w-10/12 pb-[calc(100lvh-100svh)] gap-2xl"
            >
              <header class="flex flex-col gap-2xs text-skin-base">
                <wrapper-reveal reveal="fade-from-right">
                  <h2 class="text-overline-big" v-html="item.overline"></h2>
                </wrapper-reveal>

                <wrapper-split-text reveal="words">
                  <h1 class="text-display-hero" v-html="item.title"></h1>
                </wrapper-split-text>
              </header>
              <wrapper-reveal class="self-start" reveal="fade-from-right">
                <btn-pill
                  size="big"
                  :icon="item.button.icon || undefined"
                  :title="item.button.title"
                  :action="item.button.action"
                  :filled="true"
                  :use-tracking="{
                    event: 'click_hero_home',
                    destination_url: item.button.action.to,
                  }"
                />
              </wrapper-reveal>
            </div>
          </div>

          <span
            v-if="index > 0"
            inert
            tabindex="-1"
            aria-hidden="true"
            class="absolute top-[-100%] left-0 w-full h-full overlay-gradient-from-bottom transition-opacity duration-1000 ease-in-out"
          ></span>
        </section>
      </div>
    </template>
  </block>
</template>

<script setup>
import { useTransparentAppHeader } from "@/composables/useTransparentAppHeader";
const tpl_root = templateRef("tpl_root");
const { init, destroy } = useTransparentAppHeader();

useSafeMountedEl(tpl_root, () => {
  init(tpl_root.value.$el);
});

onUnmounted(() => {
  destroy();
});
</script>

<template>
  <!-- used "opacity-0 tablet:opacity-100" instead of "invisible tablet:visible" due browser bug on low resolutions  -->
  <div
    inert
    component-name="txt-scroll-cta"
    class="opacity-0 tablet:opacity-100 overflow-clip block relative top-0 left-0 min-w-min w-min max-w-min"
    v-surfer="{
      observers: {
        init: true,
        visible: { event: true },
        offscreen: { event: true },
      },
    }"
    @surfer-visible="handleArrowAnimation(true)"
    @surfer-offscreen="handleArrowAnimation(false)"
  >
    <div
      ref="tpl_top"
      class="relative top-0 left-0 flex flex-col gap-sm justify-center items-center"
    >
      <span
        class="block uppercase [text-orientation:mixed] [writing-mode:vertical-rl] subpixel-antialiased text-body-small leading-none origin-center rotate-180 min-h-min"
        >Scroll</span
      >
      <svg-icon
        icon="arrow-long-down"
        size="regular"
        class="relative top-0 -left-px"
      />
    </div>

    <div
      ref="tpl_bottom"
      class="absolute top-0 left-0 flex flex-col gap-sm justify-center items-center invisible"
    >
      <span
        class="block uppercase [text-orientation:mixed] [writing-mode:vertical-rl] subpixel-antialiased text-body-small leading-none origin-center rotate-180 min-h-min"
        >Scroll</span
      >
      <svg-icon
        icon="arrow-long-down"
        size="regular"
        class="relative top-0 -left-px"
      />
    </div>
  </div>
</template>

<script setup>
const tpl_top = templateRef("tpl_top");
const tpl_bottom = templateRef("tpl_bottom");
const isAnimating = ref(false);
let arrowAnimInterval;
let tl;

function handleArrowAnimation(boo) {
  if (arrowAnimInterval) {
    clearInterval(arrowAnimInterval);
    arrowAnimInterval = null;
  }

  if (boo) {
    arrowAnimInterval = setInterval(animate, 3000);
  }
}

function animate(duration = 1.25, ease = "expo.inOut") {
  if (isAnimating.value) return;
  isAnimating.value = true;

  if (tl) {
    tl.kill();
    tl = null;
  }

  tl = gsap.timeline({
    onComplete: () => {
      if (tpl_top.value) gsap.set(tpl_top.value, { yPercent: 0, xPercent: 0 });

      if (tpl_bottom.value)
        gsap.set(tpl_bottom.value, {
          visibility: "hidden",
          yPercent: 0,
          xPercent: 0,
        });

      isAnimating.value = false;
    },
  });

  if (tpl_top.value) {
    tl.fromTo(
      tpl_top.value,
      { yPercent: 0 },
      {
        yPercent: 200,
        duration,
        ease,
      },
      0
    );
  }

  if (tpl_bottom.value) {
    tl.fromTo(
      tpl_bottom.value,
      { yPercent: -200 },
      {
        yPercent: 0,
        visibility: "visible",
        duration,
        ease,
      },
      0
    );
  }
}

onBeforeUnmount(() => {
  if (arrowAnimInterval) {
    clearInterval(arrowAnimInterval);
    arrowAnimInterval = null;
  }

  if (tl) {
    tl.kill();
    tl = null;
  }
});
</script>
